import React from "react";
import HorizontalBarItem from "./HorizontalBarItem";

const menuItems = [
  {
    route: "/standings",
    title: "Standings",
  },
  {
    route: "/teams",
    title: "Teams",
  },
  {
    route: "/drivers",
    title: "Drivers",
  },
];

export default function HorizontalBar(props) {
  return (
    <div className="h-14 text-white bg-red">
      <div className="h-full m-auto w-max">
        {menuItems.map((item, index) => (
          <HorizontalBarItem
            active={item.route === props.active ? true : false}
            key={index}
            route={item.route}
            title={item.title}
          />
        ))}
      </div>
    </div>
  );
}
