import React from "react";

export default function Footer(props) {
  return (
    <footer className="footer fixed dark:text-white mt-6 bottom-0 bg-red float-right w-full">
      <div className="m-auto w-max p-2">
        <h4>© 2022 Tom Bv. All rights reserved.</h4>
      </div>
    </footer>
  );
}
