import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Select from "../components/form/Select";
import HorizontalBar from "../components/navigation/HorizontalBar";
import VerticalBar from "../components/navigation/VerticalBar";
import TeamDetailPage from "../components/teamPage/TeamDetailPage";
import Title from "../components/Title";
import { getSeasons, getTeamById, getTeamsBySeason } from "../functions";

export default function Teams(props) {
  const [inputs, setInputs] = useState({
    year: new Date().getFullYear(),
  });

  const [DBdata, setDBdata] = useState({
    years: [],
    teams: [],
    teamData: [],
  });

  useEffect(() => {
    document.title = "FormulaT - Teams";

    getSeasons().then((results) => {
      setDBdata((values) => ({ ...values, years: results.data }));
    });

    //get current year
    var today = new Date();
    var year = today.getFullYear();

    getTeamsBySeason(year).then((results) => {
      setDBdata((values) => ({ ...values, teams: results.data }));
    });
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    getTeamById(value).then((results) => {
      setDBdata((values) => ({ ...values, teams: results.data[0].teams }));
    });

    setInputs((values) => ({ ...values, [name]: value }));
  };

  return (
    <div className="dark:text-white flex w-screen min-h-screen">
      <VerticalBar
        route={"/teams/" + inputs.year}
        valueName="team_name"
        items={DBdata.teams}
        active={props.match.params.team}
      >
        <Select
          name="year"
          selected={inputs.year}
          onChange={handleChange}
          classNames="sm:col-span-6"
          options={DBdata.years}
          value="year"
          id="year"
        />
      </VerticalBar>
      <div className="flex-col flex" style={{ width: "inherit" }}>
        <HorizontalBar active="/teams" />
        {props.match.params.team ? (
          <>
            <Title>{props.match.params.team}</Title>
            <TeamDetailPage teamData={DBdata.teamData} />
          </>
        ) : (
          <Title>Please select a team.</Title>
        )}
        <Footer />
      </div>
    </div>
  );
}
