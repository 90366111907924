import React from "react";

export default function TeamLogo(props) {
  // check if logo exists in link
  if (
    checkImage(
      "https://cdn.formulat.nl/teams/" + props.year + "/" + props.logo + ".jpg"
    )
  ) {
    return (
      <img
        className="w-8 inline-block"
        alt={props.logo}
        src={
          "https://cdn.formulat.nl/teams/" +
          props.year +
          "/" +
          props.logo +
          ".jpg"
        }
      />
    );
  }

  return <></>;
}

function checkImage(url) {
  var request = new XMLHttpRequest();
  request.open("GET", url, true);
  request.send();
  request.onload = function () {
    if (request.status === 200) {
      //if(statusText == OK)
      console.log("image exists");
    } else {
      console.log("image doesn't exist");
    }
  };
}
