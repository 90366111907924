import React from "react";
import { Link } from "react-router-dom";
import FlagImage from "../FlagImage";

export default function DriverNameBlock(props) {
  return (
    <td className="p-1 text-left">
      <Link to={"/drivers/" + props.name}>
        <FlagImage flagID={props.flagID} />
        <span className="p-1">{props.name}</span>
      </Link>
    </td>
  );
}
