import React from "react";
import { Link } from "react-router-dom";
import VerticalBarItem from "./VerticalBarItem";

export default function VerticalBar(props) {
  return (
    <div className="text-white min-h-full min-w-max font-semibold w-min flex-none bg-red">
      <img
        src="../../assets/logo-placeholder.png"
        alt="Home"
        className="h-14 ml-4"
      />
      <div className="w-full px-4">
        {props.children}
        {props.items.map((item, index) => (
          <Link
            className={
              "hover:text-black " +
              (item[
                props.linkId ? props.linkId : props.valueName
              ].toString() === (props.active ? props.active.toString() : "")
                ? "text-black"
                : "")
            }
            key={index}
            to={
              props.route +
              "/" +
              item[props.linkId ? props.linkId : props.valueName]
            }
          >
            <VerticalBarItem name={item[props.valueName]} />
          </Link>
        ))}
      </div>
    </div>
  );
}
