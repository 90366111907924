import React from "react";

export default function ResultsBlock(props) {
  return (
    <td
      className={"text-center p-1 text-black " + getScoreColor(props.value)}
    >
      {props.value}
      <sup className="text-red">{props.pole ? "P" : ""}</sup>
      <sup className="text-blue-600">{props.fastestLap ? "F" : ""}</sup>
    </td>
  );
}

function getScoreColor(value) {
  switch (value) {
    case "1":
      return "bg-gold";
    case "2":
      return "bg-silver";
    case "3":
      return "bg-bronze";
    case "4":
      return "bg-green";
    case "5":
      return "bg-green";
    case "6":
      return "bg-green";
    case "7":
      return "bg-green";
    case "8":
      return "bg-green";
    case "9":
      return "bg-green";
    case "10":
      return "bg-green";
    case "DNF":
      return "bg-pink";
    case "DNS":
      return "bg-white";
    case "DSQ":
      return "bg-black text-white"
    case "-":
      return "text-white";
    case "WD": 
      return "bg-white";
    default:
      return "bg-purple";
  }
}
