import React, { useEffect, useRef, useState } from "react";
import FlagImage from "../FlagImage";
import ResultsBlock from "./ResultsBlock";
import DriverNameBlock from "./DriverNameBlock";
import { getSeasonResults } from "../../functions";

export default function DriverTable(props) {
  const [results, setResults] = useState({
    races: [],
    drivers: [],
    teams: [],
    results: [],
  });

  const prevYearRef = useRef();

  useEffect(() => {
    prevYearRef.current = props.year;
  });

  const prevYear = prevYearRef.current;

  useEffect(() => {
    if (prevYear !== props.year) {
      getSeasonResults(props.year).then((returns) => {
        setResults((values) => ({ ...values, races: returns.data.races }));
        setResults((values) => ({
          ...values,
          drivers: returns.data.drivers,
        }));
        setResults((values) => ({ ...values, teams: returns.data.teams }));
        setResults((values) => ({
          ...values,
          results: returns.data.results,
        }));
      });
    }
  }, [props.year, prevYear]);

  return (
    <table id="resultsTable" className="m-auto">
      <thead>
        <tr className="bg-gray">
          <th>
            <span>Pos.</span>
          </th>
          <th>
            <span>#</span>
          </th>
          <th>
            <span>Coureur</span>
          </th>
          {results.races.map((item, index) => (
            <th className="text-center w-min px-1" key={index}>
              <FlagImage flagID={item.flagID} />
              {item.short_name}
            </th>
          ))}
          <th>
            <span>Points</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {results.drivers.map((item, index) => (
          <tr key={index}>
            <td className="text-center">{index + 1}</td>
            <td className="p-1 text-center">
              {item.override_number ? item.override_number : item.number}
            </td>
            <DriverNameBlock
              flagID={item.flagID}
              name={item.name}
              id={item._id}
            />
            <DriverResults
              handleClick={props.handleClick}
              races={results.races}
              results={results.results}
              driver_name={item.name}
            />
            <td className="text-center">{item.total_points}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function DriverResults(props) {
  var results = props.results;
  var races = props.races;
  var driver_name = props.driver_name;

  var information = [];

  races.forEach((raceElement, index) => {
    if (
      results !== undefined &&
      checkInArray(results, raceElement.name, driver_name)
    ) {
      results.forEach((resultElement) => {
        if (
          resultElement.race_name === raceElement.name &&
          resultElement.driver_name === driver_name
        ) {
          information.push(
            <ResultsBlock
              handleClick={props.handleClick}
              key={index}
              value={resultElement.position}
              pole={resultElement.grid === 1 ? true : false}
              fastestLap={resultElement.fastest_lap === 1 ? true : false}
              race_name={resultElement.race_name}
              driver_name={driver_name}
            />
          );
        } else {
          <ResultsBlock
            handleClick={props.handleClick}
            key={index}
            value={"-"}
            pole={false}
            fastestLap={false}
            race_name={resultElement.race_name}
            driver_name={driver_name}
          />;
        }
      });
    } else {
      information.push(
        <ResultsBlock
          handleClick={props.handleClick}
          key={index}
          value={"-"}
          pole={false}
          fastestLap={false}
          race_name={raceElement.race_name}
          driver_name={driver_name}
        />
      );
    }
  });

  return information;
}

function checkInArray(array, race_name, driver_name) {
  var state = false;
  array.forEach((element) => {
    if (
      (element.race_name === race_name &&
        element.driver_name === driver_name) ||
      state
    ) {
      state = true;
    } else {
      state = false;
    }
  });
  return state;
}
