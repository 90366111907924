import React from "react";

export default function label(props) {
  return (
    <label
      htmlFor={props.for}
      className="block text-sm font-medium text-gray-700"
    >
      {props.children}
    </label>
  );
}
