import React from "react";
import Label from "../form/Label";

export default function Select(props) {
  function getValue(option) {
    if (Array.isArray(props.id)) {
      let value = "";
      props.id.forEach((element) => {
        if (value !== "") {
          value = value + " - ";
        }

        value = value + option[element];
      });

      return value;
    } else {
      return option[props.id];
    }
  }

  return (
    <div className={"col-span-6 " + props.classNames}>
      <Label for={props.name}>{props.label}</Label>
      <select
        disabled={props.disabled}
        value={props.selected}
        id={props.name}
        name={props.name}
        onChange={props.onChange}
        className="mt-1 block w-full py-2 px-3 border border-gray-300 text-black rounded-md shadow-sm focus:ring-red focus:ring-2 sm:text-sm"
      >
        {props.options.map((option, index) => (
          <option key={index} value={option[props.value]}>
            {getValue(option)}
          </option>
        ))}
      </select>
    </div>
  );
}
