import React, { useEffect, useRef, useState } from "react";
import { getSeasonResults } from "../../functions";
import FlagImage from "../FlagImage";
import DriverNameBlock from "./DriverNameBlock";
import ResultsBlock from "./ResultsBlock";
import TeamLogo from "./TeamLogo";

export default function TeamTable(props) {
  let firstRow;

  const [results, setResults] = useState({
    races: [],
    drivers: [],
    teams: [],
    results: [],
    teamDrivers: [],
  });

  const prevYearRef = useRef();

  useEffect(() => {
    prevYearRef.current = props.year;
  });

  const prevYear = prevYearRef.current;

  useEffect(() => {
    if (prevYear !== props.year) {
      getSeasonResults(props.year).then((returns) => {
        setResults((values) => ({ ...values, races: returns.data.races }));
        setResults((values) => ({
          ...values,
          drivers: returns.data.drivers,
        }));
        setResults((values) => ({ ...values, teams: returns.data.teams }));
        setResults((values) => ({
          ...values,
          results: returns.data.results,
        }));
        setResults((values) => ({
          ...values,
          teamDrivers: returns.data.teamDrivers,
        }));
      });
    }
  }, [props.year, prevYear]);

  return (
    <table id="resultsTable" className="mx-auto mb-20">
      <thead>
        <tr className="bg-gray">
          <th>
            <span>Pos.</span>
          </th>
          <th>
            <span>Constructeur</span>
          </th>
          <th>
            <span>Coureur</span>
          </th>
          {results.races.map((item, index) => (
            <th className="text-center w-min px-1" key={index}>
              <FlagImage flagID={item.flagID} />
              {item.short_name}
            </th>
          ))}
          <th>
            <span>Points</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {results.teams.map((teamElement, teamIndex) => {
          firstRow = true;
          return results.drivers.map((driverElement, driverIndex) => {
            if (
              driverHasTeamResults(
                results.teamDrivers,
                driverElement.name,
                teamElement.team_name
              )
            ) {
              var teamResultsRow;
              if (firstRow) {
                teamResultsRow = (
                  <tr key={driverIndex}>
                    <td
                      rowSpan={teamElement.driver_count}
                      className="text-center"
                    >
                      {teamIndex + 1}
                    </td>
                    <td
                      rowSpan={teamElement.driver_count}
                      className="p-1 text-left"
                    >
                      <TeamLogo logo={teamElement.logo} year={props.year} />
                      <span className="p-1">{teamElement.name}</span>
                    </td>
                    <DriverNameBlock
                      flagID={driverElement.flagID}
                      name={driverElement.name}
                      id={driverElement.name}
                    />
                    <DriverResults
                      races={results.races}
                      results={results.results}
                      team_name={teamElement.team_name}
                      driver_name={driverElement.name}
                      teamDrivers={results.teamDrivers}
                    />
                    <td
                      rowSpan={teamElement.driver_count}
                      className="text-center"
                    >
                      {teamElement.team_total}
                    </td>
                  </tr>
                );
                firstRow = false;
              } else {
                teamResultsRow = (
                  <tr key={driverIndex}>
                    <DriverNameBlock
                      flagID={driverElement.flagID}
                      name={driverElement.name}
                      id={driverElement.name}
                    />
                    <DriverResults
                      races={results.races}
                      results={results.results}
                      team_name={teamElement.team_name}
                      driver_name={driverElement.name}
                      teamDrivers={results.teamDrivers}
                    />
                  </tr>
                );
              }
              return teamResultsRow;
            }

            return "";
          });
        })}
      </tbody>
    </table>
  );
}

function driverHasTeamResults(results, driver_name, team_name) {
  let state = false;
  results.forEach((resultsElement) => {
    if (
      resultsElement.driver_name === driver_name &&
      resultsElement.team_name === team_name
    ) {
      state = true;
    }
  });
  return state;
}

function DriverResults(props) {
  var results = props.results;
  var races = props.races;
  var driver_name = props.driver_name;
  var team_name = props.team_name;
  var information = [];

  races.forEach((raceElement, index) => {
    if (
      results !== undefined &&
      checkInArray(results, raceElement.name, driver_name)
    ) {
      results.forEach((resultElement) => {
        if (
          resultElement.race_name === raceElement.name &&
          resultElement.driver_name === driver_name
        ) {
          if (resultElement.team_name === team_name) {
            information.push(
              <ResultsBlock
                key={index}
                value={resultElement.position}
                pole={resultElement.grid === 1 ? true : false}
                fastestLap={resultElement.fastest_lap === 1 ? true : false}
              />
            );
          } else {
            information.push(
              <ResultsBlock
                key={index}
                value={"-"}
                pole={false}
                fastestLap={false}
              />
            );
          }
        }
      });
    } else {
      information.push(
        <ResultsBlock key={index} value={"-"} pole={false} fastestLap={false} />
      );
    }
  });

  return information;
}

function checkInArray(array, race_name, driver_name) {
  var state = false;

  array.forEach((element) => {
    if (
      (element.race_name === race_name &&
        element.driver_name === driver_name) ||
      state
    ) {
      state = true;
    } else {
      state = false;
    }
  });
  return state;
}
