import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import HorizontalBar from "../components/navigation/HorizontalBar";
import VerticalBar from "../components/navigation/VerticalBar";
import DriverTable from "../components/results/DriverTable";
import TeamTable from "../components/results/TeamTable";
import Title from "../components/Title";
import { getSeasons } from "../functions";

export default function Standings(props) {
  const [verticalBarItems, setVerticalBarItems] = useState([]);

  useEffect(() => {
    document.title = "FormulaT - Standings";

    getSeasons().then((results) => {
      setVerticalBarItems(results.data);
    });
  }, []);

  return (
    <div className="dark:text-white flex w-screen min-h-screen">
      <VerticalBar
        route="/standings"
        valueName="year"
        items={verticalBarItems}
        active={
          props.match.params.year
            ? props.match.params.year
            : new Date().getFullYear()
        }
      />

      <div className="flex-col flex" style={{ width: "inherit" }}>
        <HorizontalBar active="/standings" />
        <div className="overflow-y-auto">
          <Title>Drivers' championship</Title>
          <DriverTable
            year={
              props.match.params.year
                ? props.match.params.year
                : new Date().getFullYear()
            }
          />
        </div>

        <div className="float-right w-full">
          <Title>Constructors' championship</Title>
          <TeamTable
            year={
              props.match.params.year
                ? props.match.params.year
                : new Date().getFullYear()
            }
          />
        </div>

        <Footer />
      </div>
    </div>
  );
}
