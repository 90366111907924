import React from "react";

export default function FlagImage(props) {
  return (
    <img
      className="w-6 inline-block"
      alt={props.flagID}
      src={"../../assets/flags/" + props.flagID + ".svg"}
    />
  );
}
