import React from "react";
import Label from "../form/Label";

export default function TextInput({
  classNames = "",
  label = "",
  name,
  value,
  onChange,
  placeholder = "",
}) {
  return (
    <div className={"col-span-6 " + classNames}>
      {label && <Label name={name}>{label}</Label>}
      <input
        type="text"
        name={name}
        id={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        className="mt-1 focus:ring-red focus:ring-2 py-2 px-3 text-black block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
      />
    </div>
  );
}
