import React from "react";
import { Link } from "react-router-dom";

export default function HorizontalBarItem(props) {
  return (
    <div
      className={
        "float-left h-full text-xl font-semibold hover:text-black " +
        (props.active ? "text-black" : "")
      }
    >
      <Link className="pr-4 pl-4 h-full items-center flex" to={props.route}>
        <span>{props.title}</span>
      </Link>
    </div>
  );
}
