import React from "react";
import { Link } from "react-router-dom";
import FlagImage from "../FlagImage";

export default function DriverCard(props) {
  var driver = props.driver;

  return (
    <Link to={"/drivers/" + driver.name}>
      <div className="text-white w-full p-3 border-2 border-solid float-left">
        <h3>
          <FlagImage flagID={driver.flagID} />
          &nbsp;{driver.name}
        </h3>
      </div>
    </Link>
  );
}
