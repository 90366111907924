import React from "react";
import DriverDetailPage from "../components/driverPage/DriverDetailPage";
import DriverOverviewPage from "../components/driverPage/DriverOverviewPage";
import Footer from "../components/Footer";
import HorizontalBar from "../components/navigation/HorizontalBar";

export default function Drivers(props) {
  return (
    <div className="flex-col flex text-white" style={{ width: "inherit" }}>
      <HorizontalBar active="/drivers" />
      {props.match.params.driverName ? (
        <DriverDetailPage driverName={props.match.params.driverName} />
      ) : (
        <DriverOverviewPage />
      )}
      <Footer />
    </div>
  );
}
