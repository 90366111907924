import React, { useEffect, useState } from "react";
import { getAge, getDriverByName, getFormattedDate } from "../../functions";
import DriverInfo from "../DriverInfo";
import DriverProfilePic from "../DriverProfilePic";
import Title from "../Title";

export default function DriverDetailPage(props) {
  const [driverData, setDriverData] = useState([]);

  useEffect(() => {
    getDriverByName(props.driverName).then((results) => {
      setDriverData(results.data);
    });
  }, []);

  return (
    <>
      <Title>{driverData.name}</Title>
      <div className="w-2/3 m-auto mt-5 grid grid-cols-2 gap-6">
        <DriverProfilePic
          src={"https://cdn.formulat.nl/driverPic/" + driverData.profilePic}
        />
        <div className="col-span-1">
          <DriverInfo name="Country" value={driverData.country_name} />
          <DriverInfo name="Podiums" value={driverData.podiums} />
          <DriverInfo
            name="Points"
            value={driverData.total_points ? driverData.total_points : "0"}
          />
          <DriverInfo
            name="Grand Prix entered"
            value={driverData.entries ? driverData.entries : "0"}
          />
          <DriverInfo
            name="World Championships"
            value={driverData.world_championships}
          />
          <DriverInfo
            name="Highest race finish"
            value={
              driverData.highest_position +
              " (" +
              driverData.position_count +
              ")"
            }
          />
          <DriverInfo name="Highest grid position" />
          <DriverInfo
            name="Date of birth"
            value={
              getFormattedDate(driverData.born) +
              " (" +
              getAge(driverData.born) +
              ")"
            }
          />
          <DriverInfo name="Place of birth" value={driverData.birthplace} />
        </div>
      </div>
    </>
  );
}
