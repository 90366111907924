import React from "react";
import Footer from "../components/Footer";
import HorizontalBar from "../components/navigation/HorizontalBar";
import DriverTable from "../components/results/DriverTable";
import Title from "../components/Title";

export default function Home(props) {
  return (
    <div className="dark:text-white flex w-screen min-h-screen">
      <div className="flex-col flex" style={{ width: "inherit" }}>
        <HorizontalBar active="/home" />
        <div className="flex-grow overflow-y-auto">
          <Title>Driver championships: {new Date().getFullYear()}</Title>
          <DriverTable year={new Date().getFullYear()} />
        </div>
        <Footer />
      </div>
    </div>
  );
}
