require("dotenv").config();

export function getFormattedDate(date) {
  date = new Date(date);
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var year = date.getFullYear();
  return day + "-" + month + "-" + year;
}

export function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

//Driver API calles
export async function getDrivers() {
  const results = await fetch(process.env.REACT_APP_APIROUTE + "drivers", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return await results.json();
}

export async function getDriverByName(name) {
  const results = await fetch(
    process.env.REACT_APP_APIROUTE + "drivers/" + name,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return await results.json();
}

export async function getDriversBySeason(season) {
  const results = await fetch(
    process.env.REACT_APP_APIROUTE + "seasons/" + season + "/drivers",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return await results.json();
}

//Season API calles
export async function getSeasonResults(season) {
  const returns = await fetch(
    process.env.REACT_APP_APIROUTE + "seasons/" + season,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return await returns.json();
}

export async function getSeasons() {
  const results = await fetch(process.env.REACT_APP_APIROUTE + "seasons", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return await results.json();
}

//Races API calles
export async function getRaces() {
  const results = await fetch(process.env.REACT_APP_APIROUTE + "races", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return await results.json();
}

//Teams API calles
export async function getTeams() {
  const results = await fetch(process.env.REACT_APP_APIROUTE + "teams", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return await results.json();
}

export async function getTeamsBySeason(season) {
  const results = await fetch(
    process.env.REACT_APP_APIROUTE + "seasons/" + season + "/teams",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return await results.json();
}

export async function getTeamById(id) {
  const results = await fetch(process.env.REACT_APP_APIROUTE + "teams/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
  return await results.json();
}

export function addTeam(inputs) {
  return fetch(process.env.REACT_APP_APIROUTE + "teams", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(inputs),
  });
}
