import React, { useEffect, useState } from "react";
import { getDrivers } from "../../functions";
import TextInput from "../form/TextInput";
import Title from "../Title";
import DriverCard from "./driverCard";

export default function DriverOverviewPage(props) {
  const [inputs, setInputs] = useState({
    driverName: "",
  });

  const [DBdata, setDBdata] = useState({
    drivers: [],
  });

  useEffect(() => {
    getDrivers().then((results) => {
      setDBdata((values) => ({ ...values, drivers: results.data }));
    });
  }, []);

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Title>Please select a driver.</Title>
      <TextInput
        name="driverName"
        onChange={handleChange}
        value={inputs.driverName}
        placeholder="Driver name"
        classNames="w-1/3 mx-auto"
      />
      <div className="m-auto grid grid-cols-4 gap-4 p-4">
        {DBdata.drivers.map(
          (driver) =>
            driver.name
              .toLowerCase()
              .includes(inputs.driverName.toLowerCase()) && (
              <DriverCard key={driver.name} driver={driver} />
            )
        )}
      </div>
    </div>
  );
}
