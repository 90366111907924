import React from "react";

export default function DriverInfo(props) {
  return (
    <div className="text-xl">
      <span className="w-1/2 font-bold">{props.name + ":"}&nbsp;</span>
      <span className="float-right">{props.value ? props.value : "N/A"}</span>
    </div>
  );
}
