import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./views/Home";
import Standings from "./views/Standings";
import Teams from "./views/Teams";
import Drivers from "./views/Drivers";

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/standings/:year" component={Standings} />
      <Route path="/standings" component={Standings} />
      <Route path="/teams/:year/:team" component={Teams} />
      <Route path="/teams" component={Teams} />
      <Route path="/drivers/:driverName" component={Drivers} />
      <Route path="/drivers" component={Drivers} />
      <Route path="/" component={Home} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
